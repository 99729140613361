<template>
  <a-drawer
    class="detail-drawer"
    :closable="false"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1080"
    @close="closeDrawer"
  >
    <a-tabs v-model="tabKey" v-if="detail.id">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          :gutter="16"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <!-- 健康仪表盘 -->
          <a-col :span="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div
                style="
                  height: 60px;
                  margin-bottom: 8px;
                  display: grid;
                  grid-template-rows: 1fr 1fr;
                  justify-items: center;
                  grid-template-columns: 2fr 3fr;
                  line-height: 30px;
                "
              >
                <div style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">
                  资源状态
                </div>
                <source-status-tag :status="detail.status"></source-status-tag>
                <div style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">
                  监控状态
                </div>
                <div>
                  <monitor-status-tag
                    v-for="item in monitorStatuses"
                    :key="item.name"
                    :status="item.value"
                    :name="item.name"
                  ></monitor-status-tag>
                </div>
              </div>
              <dashboard-chart
                v-if="showScoreChart"
                chart-id="overview-health-chart"
                :height="110"
                :chart-data="dashboardData"
              ></dashboard-chart>
            </div>
          </a-col>
          <!-- 硬件信息 -->
          <a-col :span="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">软件信息</div>
              <div
                style="
                  position: absolute;
                  right: 10px;
                  top: 12px;
                  font-size: 11px;
                "
              >
                <a @click="$refs.hardwareModal.show()">更多</a>
              </div>

              <hardware-description :detail="detail"></hardware-description>
              <info-modal ref="hardwareModal" class="description-in-modal">
                <template>
                  <hardware-description :detail="detail" :show-more="true"></hardware-description>
                </template>
              </info-modal>
            </div>
          </a-col>
          <!-- 监控信息 -->
          <a-col :span="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">监控信息</div>
              <a-descriptions
                :column="1"
                class="description-overflow-hidden description-item-12px"
              >
                <a-descriptions-item label="ID">
                  <content-tooltip
                    v-if="detail.id"
                    :title="detail.id.replaceAll('-', '')"
                    :body="detail.id.replaceAll('-', '')"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="所属群组">
                  <a-tag v-for="group in detail.groups" :key="group.id">
                    {{ group.name }}
                  </a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="IP 地址">
                  <content-tooltip
                    v-if="detail.os"
                    :title="detail.os.monitor_address"
                    :body="detail.os.monitor_address"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="监控端口">
                  <content-tooltip
                    v-if="detail.monitor_port"
                    :title="detail.monitor_port"
                    :body="detail.monitor_port"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-col>
          <!-- 告警情况 -->
          <a-col :span="24" style="margin-bottom: 16px">
            <div class="module-container" style="height: 320px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">告警情况</div>
              <overview-alerts :dataSource="alertsData" :loading="loading"></overview-alerts>
            </div>
          </a-col>
          <!-- 风险情况 -->
          <!-- <a-col :span="24" style="margin-bottom: 16px">
            <div class="module-container" style="height: 340px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">风险情况</div>
              <display-table :columns="RiskColumns"></display-table>
            </div>
          </a-col> -->
          <a-col :span="24">
            <div class="module-container">
              <software-page :detail="detail"></software-page>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane
        v-if="['mysql', 'postgresql', 'mssql'].indexOf(detail.database_type) !== -1"
        key="performance"
        tab="性能分析"
      >
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <a-button
            :loading="performanceLoading"
            type="primary"
            style="margin-bottom: 16px"
            @click="handelClick"
          >
            {{ detail.database_type === 'mssql' ? '获取锁信息' : '获取慢 SQL' }}
          </a-button>
          <a-table
            class="custom-table"
            tableLayout="auto"
            :columns="performanceColumns"
            :data-source="performanceDataSource"
            :loading="performanceLoading"
            :row-key="record => {
              const key = Object.keys(record)[0]
              return key
            }"
            :scroll="{ x: true }"
            :total="performanceTotal"
          >
            <div v-for="item in performanceColumns" :key="item.dataIndex">
              <template :slot="item.dataIndex" slot-scope="text">
                <div>
                  <span v-if="item.dataIndex === 'query'" style="white-space: pre-line">{{ text }}</span>
                  <span v-else>{{ text || '-' }}</span>
                </div>
              </template>
            </div>
          </a-table>
        </div>
      </a-tab-pane>

      <a-tab-pane key="monitor" tab="指标详情">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </div>
      </a-tab-pane>

      <a-tab-pane key="rule" tab="告警规则">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="alert" tab="历史告警">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <alert-table source-type="database" :source-id="detail.id"></alert-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import store from '@/store'
import moment from 'moment'
import {
  getDatabase,
  getDatabaseMonitorStatusList,
  getDatabaseMonitorItemList,
  getDatabaseMonitorHistory,
  getDatabaseMonitorTriggerList,
  updateDatabaseMonitorTrigger,
  getDatabaseHealthScore,
  getDatabaseAlertList
} from '@/api/database'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import ContentTooltip from '@/components/ContentTooltip'
import SourceStatusTag from '@/components/tag/SourceStatusTag'

import DashboardChart from '@/components/chart/DashboardChart'
import HardwareDescription from './modules/HardwareDescription'
import InfoModal from '@/components/source-detail-page/InfoModal'
// import DisplayTable from '@/components/source-detail-page/DisplayTable'

import MonitorItemTable from '@/components/table/MonitorItemTable'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'
import OverviewAlerts from '@/components/source-detail-page/OverviewAlerts'

import SoftwarePage from './SoftwarePage/index.vue'
import AlertTable from '@/components/table/AlertTable.vue'

export default {
  name: 'DatabaseDrawer',
  components: {
    AlertTable,
    MonitorStatusTag,
    DashboardChart,
    HardwareDescription,
    InfoModal,
    ContentTooltip,
    // DisplayTable,
    MonitorItemTable,
    MonitorTriggerTable,
    SoftwarePage,
    OverviewAlerts,
    SourceStatusTag
  },
  data () {
    return {
      visible: false,
      tabKey: 'overview',
      monitorStatuses: [],
      getItemListFunc: getDatabaseMonitorItemList,
      historyFunc: getDatabaseMonitorHistory,
      getTriggerFunc: getDatabaseMonitorTriggerList,
      updateFunc: updateDatabaseMonitorTrigger,
      detail: {
        id: '',
        name: '',
        version: '',
        monitor_port: '',
        database_type: 'unknown',
        os: {
          id: '',
          name: '',
          monitor_address: ''
        },
        created_at: '',
        updated_at: '',
        groups: [],
        status: 'unknown'
      },
      loading: false,
      alertsData: [],
      RiskColumns: [
        {
          dataIndex: 'type',
          align: 'center',
          title: '风险分类',
          width: 80,
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          dataIndex: 'monitor',
          align: 'center',
          title: '监控项',
          width: 80,
          scopedSlots: {
            customRender: 'monitor'
          }
        },
        {
          dataIndex: 'current_monitor',
          align: 'center',
          title: '当前指标',
          width: 80,
          scopedSlots: {
            customRender: 'currentMonitor'
          }
        },
        {
          dataIndex: 'description',
          align: 'description',
          title: '描述',
          width: 80,
          scopedSlots: {
            customRender: 'description'
          }
        }
      ],
      performanceTotal: 0,
      performanceDataSource: [],
      performanceLoading: false,
      showScoreChart: false,
      dashboardData: []
    }
  },
  computed: {
    performanceKey () {
      if (this.detail.database_type === 'mysql') {
        return 'mysql.sql.slow["{$MYSQL.DSN}","{$MYSQL.USER}","{$MYSQL.PASSWORD}","{$MYSQL.QUERY_TIME.MAX.WARN}"]'
      } else if (this.detail.database_type === 'postgresql') {
        return 'pgsql.sql.slow["{$PG.URI}","{$PG.USER}","{$PG.PASSWORD}","{$PG.DATABASE}","{$PG.QUERY_ETIME.MAX.WARN}"]'
      } else if (this.detail.database_type === 'mssql') {
        return 'mssql.lock.info["{$MSSQL.DSN}","{$MSSQL.USER}","{$MSSQL.PASSWORD}"]'
      } else return ''
    },
    performanceColumns () {
      if (this.detail.database_type === 'mysql') {
        return [
          {
            dataIndex: 'ID',
            title: 'ID'
          },
          {
            dataIndex: 'USER',
            title: '用户'
          },
          {
            dataIndex: 'HOST',
            title: '客户端'
          },
          {
            dataIndex: 'DB',
            title: '数据库'
          },
          {
            dataIndex: 'COMMAND',
            title: '命令'
          },
          {
            dataIndex: 'TIME',
            title: '时间'
          },
          {
            dataIndex: 'STATE',
            title: '状态'
          },
          {
            dataIndex: 'INFO',
            title: '语句'
          }
        ]
      } else if (this.detail.database_type === 'postgresql') {
        return [
          {
            dataIndex: 'datid',
            title: 'ID'
          },
          {
            dataIndex: 'datname',
            title: '数据库'
          },
          {
            dataIndex: 'pid',
            title: '进程 ID'
          },
          {
            dataIndex: 'leader_pid',
            title: 'Leader 进程 ID'
          },
          {
            dataIndex: 'usesysid',
            title: '用户 ID'
          },
          {
            dataIndex: 'usename',
            title: '用户名'
          },
          {
            dataIndex: 'application_name',
            title: '应用名'
          },
          {
            dataIndex: 'client_addr',
            title: '客户端 IP 地址'
          },
          {
            dataIndex: 'client_hostname',
            title: '客户端主机名'
          },
          {
            dataIndex: 'client_port',
            title: '客户端端口号'
          },
          {
            dataIndex: 'backend_start',
            title: '客户端连接时间'
          },
          {
            dataIndex: 'xact_start',
            title: '事务开始时间'
          },
          {
            dataIndex: 'query_start',
            title: '查询开始时间'
          },
          {
            dataIndex: 'state_change',
            title: '状态更改时间'
          },
          {
            dataIndex: 'wait_event_type',
            title: '等待事件类型'
          },
          {
            dataIndex: 'wait_event',
            title: '等待事件名称'
          },
          {
            dataIndex: 'state',
            title: '当前整体状态'
          },
          {
            dataIndex: 'backend_xid',
            title: '事务标识符'
          },
          {
            dataIndex: 'backend_xmin',
            title: 'xmin 范围'
          },
          {
            dataIndex: 'query_id',
            title: '查询标识符'
          },
          {
            dataIndex: 'query',
            title: '查询文本'
          },
          {
            dataIndex: 'backend_type',
            title: '后端类型'
          }
        ]
      } else if (this.detail.database_type === 'mssql') {
        return [
          {
            dataIndex: 'request_session_id',
            title: '会话 ID'
          },
          {
            dataIndex: 'db_name',
            title: '数据库名称'
          },
          {
            dataIndex: 'resource_type',
            title: '资源类型'
          },
          {
            dataIndex: 'resource_description',
            title: '资源说明'
          },
          {
            dataIndex: 'object_name',
            title: '对象名称'
          },
          {
            dataIndex: 'request_mode',
            title: '请求模式'
          },
          {
            dataIndex: 'request_status',
            title: '请求状态'
          }
        ]
      } else return []
    }
  },
  methods: {
    show (id) {
      getDatabase(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
      getDatabaseMonitorStatusList(id).then(res => {
        this.monitorStatuses = res.data.data
      })
      const params = {
        recovered: false,
        source_id: id
      }
      this.loading = true
      getDatabaseAlertList(params).then(res => {
        this.alertsData = res.data.data
      }).finally(() => {
        this.loading = false
      })

      getDatabaseHealthScore(id).then(res => {
        this.dashboardData = [{ value: res.data }]
      }).finally(() => {
        this.showScoreChart = true
      })
    },
    handelClick () {
      this.performanceLoading = true
      getDatabaseMonitorHistory(this.detail.id, {
        key: this.performanceKey,
        datetime_from: moment().subtract(1, 'minute').format('YYYY-MM-DD HH:mm'),
        page: 1,
        page_size: 1
      }).then(res => {
        if (res.data.total) {
          this.performanceDataSource = JSON.parse(res.data.data[0].value).data
        }
      }).finally(() => {
        this.performanceLoading = false
      })
    },
    closeDrawer () {
      this.visible = false
      store.state.cancelRequest.promiseArr.forEach((e, index) => {
        e.cancel('取消请求')
      })
      store.commit('cancelRequest/CLEAR_PROMISE_ARR')
      this.detail = {
        id: '',
        name: '',
        version: '',
        monitor_port: '',
        database_type: 'unknown',
        os: {
          id: '',
          name: '',
          monitor_address: ''
        },
        created_at: '',
        updated_at: '',
        groups: [],
        status: 'unknown'
      }
      this.tabKey = 'overview'
      this.alertsData = []
      this.monitorStatuses = []
      this.showScoreChart = false
    }
  }
}
</script>
