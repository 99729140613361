<template>
  <div class="software-page">
    <div style="margin-bottom: 16px">
      <datetime-range
        :allow-clear="false"
        :default-value="datetimeRange"
        @ok="
          v => {
            datetimeRange = v
          }
        "
      ></datetime-range>
    </div>
    <a-row :gutter="16">
      <a-col
        v-for="item in monitorItems"
        :key="item.key"
        :span="12"
        style="margin-bottom: 16px;"
      >
        <monitor-chart-card
          :datetime-range="datetimeRange"
          :history-func="historyFunc"
          :item="item"
          :source-id="detail.id"
        ></monitor-chart-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import { getDatabaseMonitorHistory } from '@/api/database'
import DatetimeRange from '@/components/DatetimeRange'
import MonitorChartCard from '@/components/card/MonitorChartCard'

export default {
  name: 'SoftwarePage',
  components: {
    DatetimeRange,
    MonitorChartCard
  },
  props: {
    detail: {
      type: Object
    }
  },
  computed: {
    monitorItems () {
      switch (this.detail.database_type) {
        case 'mysql':
          return [
            {
              key: 'mysql.com_insert.rate',
              title: '每秒插入操作数'
            },
            {
              key: 'mysql.com_delete.rate',
              title: '每秒删除操作数'
            },
            {
              key: 'mysql.com_update.rate',
              title: '每秒更新操作数'
            },
            {
              key: 'mysql.com_select.rate',
              title: '每秒查询操作数'
            },
            {
              key: 'mysql.queries.rate',
              title: 'QPS'
            },
            {
              key: 'mysql.slow_queries.rate',
              title: '慢查询'
            },
            {
              key: 'mysql.buffer_pool_utilization',
              title: '缓存使用率'
            },
            {
              key: 'mysql.buffer_pool_efficiency',
              title: '缓存命中率'
            }
          ]
        case 'postgresql':
          return [
            {
              key: 'pgsql.dbstat.sum.tup_inserted.rate',
              title: '每秒插入操作数'
            },
            {
              key: 'pgsql.dbstat.sum.tup_deleted.rate',
              title: '每秒删除操作数'
            },
            {
              key: 'pgsql.dbstat.sum.tup_updated.rate',
              title: '每秒更新操作数'
            },
            {
              key: 'pgsql.cache.hit',
              title: '缓存命中率'
            }
          ]
        case 'oracle':
          return [
            {
              key: 'oracle.session_active_user',
              title: '活跃用户会话数'
            },
            {
              key: 'oracle.session_lock_rate',
              title: '会话锁'
            },
            {
              key: 'oracle.buffer_cache_hit_ratio',
              title: '缓存命中率'
            },
            {
              key: 'oracle.proc.stats["{$ORACLE.CONNSTRING}","{$ORACLE.USER}","{$ORACLE.PASSWORD}","{$ORACLE.SERVICE}"]',
              title: '进程数'
            }
          ]
        case 'mssql':
          return [
            {
              key: 'mssql.user_connections',
              title: '用户连接数'
            },
            {
              key: 'mssql.buffer_cache_hit_ratio',
              title: '缓存命中率'
            },
            {
              key: 'mssql.lock_wait_time',
              title: '锁等待时间 (ms)'
            },
            {
              key: 'mssql.processes_blocked',
              title: '阻塞进程数'
            }
          ]
        case 'redis':
          return [
            {
              key: 'redis.stats.instantaneous_ops.rate',
              title: 'OPS'
            },
            {
              key: 'redis.stats.rejected_connections',
              title: '拒绝连接数'
            },
            {
              key: 'redis.clients.connected',
              title: '连接的客户端数量'
            },
            {
              key: 'redis.clients.blocked',
              title: '阻塞的客户端数量'
            },
            {
              key: 'redis.memory.used_memory',
              title: '已使用内存'
            },
            {
              key: 'redis.memory.fragmentation_ratio',
              title: '内存碎片率'
            }
          ]
        case 'memcached':
          return [
            {
              key: 'memcached.stats.threads',
              title: '线程数'
            },
            {
              key: 'memcached.stats.bytes_read.rate',
              title: '每秒读取字节数'
            },
            {
              key: 'memcached.connections.queued.rate',
              title: '每秒排队的连接数'
            },
            {
              key: 'memcached.stats.bytes_written.rate',
              title: '每秒写入字节数'
            },
            {
              key: 'memcached.commands.set.rate',
              title: '每秒 SET 请求数'
            },
            {
              key: 'memcached.commands.get.rate',
              title: '每秒 GET 请求数'
            },
            {
              key: 'memcached.commands.flush.rate',
              title: '每秒 FLUSH 请求数'
            },
            {
              key: 'memcached.connections.current',
              title: '当前连接数'
            }
          ]
        case 'mongodb':
          return [
            {
              key: 'mongodb.opcounters.insert.rate',
              title: '每秒插入操作数'
            },
            {
              key: 'mongodb.opcounters.delete.rate',
              title: '每秒删除操作数'
            },
            {
              key: 'mongodb.opcounters.update.rate',
              title: '每秒更新操作数'
            },
            {
              key: 'mongodb.opcounters.query.rate',
              title: '每秒查询操作数'
            },
            {
              key: 'mongodb.opcounters.command.rate',
              title: '每秒命令数'
            }
          ]
        case 'elasticsearch':
          return [
            {
              key: 'es.indices.docs.count',
              title: '文档数'
            },
            {
              key: 'es.nodes.fs.total_in_bytes',
              title: '文档字节数'
            },
            {
              key: 'es.cluster.number_of_pending_tasks',
              title: '未执行的任务数'
            },
            {
              key: 'es.cluster.unassigned_shards',
              title: '未分配的分片数'
            }
          ]
        case 'dameng':
          return [
            {
              key: 'dm.insert_statements.rate',
              title: '每秒插入操作数'
            },
            {
              key: 'dm.delete_statements.rate',
              title: '每秒删除操作数'
            },
            {
              key: 'dm.update_statements.rate',
              title: '每秒更新操作数'
            },
            {
              key: 'dm.select_statements.rate',
              title: '每秒查询操作数'
            },
            {
              key: 'dm.memory_used_bytes',
              title: '内存占用'
            },
            {
              key: 'dm.logic_read_count.rate',
              title: '每秒逻辑读次数'
            },
            {
              key: 'dm.physical_read_count.rate',
              title: '每秒物理读次数'
            },
            {
              key: 'dm.physical_write_count.rate',
              title: '每秒物理写次数'
            },
            {
              key: 'dm.table_scan_count.rate',
              title: '每秒全表扫描次数'
            },
            {
              key: 'dm.total_bytes_received_from_client.rate',
              title: '每秒接收字节数'
            },
            {
              key: 'dm.total_bytes_sent_to_client.rate',
              title: '每秒接收字节数'
            },
            {
              key: 'dm.transaction_total_count.rate',
              title: '每秒事务数'
            }
          ]
        case 'clickhouse':
          return [
            {
              key: 'clickhouse.connections.tcp',
              title: '当前 TCP 连接数'
            },
            {
              key: 'clickhouse.connections.http',
              title: '当前 HTTP 连接数'
            },
            {
              key: 'clickhouse.query.insert.rate',
              title: '每秒插入操作数'
            },
            {
              key: 'clickhouse.query.select.rate',
              title: '每秒查询操作数'
            }
          ]
        default:
          return []
      }
    }
  },
  data () {
    return {
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      historyFunc: getDatabaseMonitorHistory
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="less">
.software-page {
  padding: 0 4px;
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px 16px 8px 16px;
    margin-right: 1px;
    overflow: hidden;
    border: 1px solid #e5e5e5;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }

    .ant-descriptions-item-content {
      font-size: 14px;
    }
  }
}
</style>
